import React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import { ArrowCircleRightIcon } from "@heroicons/react/outline";

import Header from "src/components/headers/header";
import Ariane from "src/components/headers/ariane";
import Footer from "src/components/footers/footer";

// import Engagement from "../../components/shortcodes/Engagement";
// import Cta from "../../components/shortcodes/Cta";
// import Presse from "../../components/shortcodes/Presse";
// import Prix from "../../components/shortcodes/Prix";
// import CtaCommande from "../../components/shortcodes/CtaCommande";
//
// const shortcodes = { Engagement, Cta, Presse, Prix, CtaCommande };

const mon_fil = ["Boutique", "/boutique/"];

const MdxPage = () => {
  return (
    <div className="bg-gray-50">
      <Helmet>
        <title>
          Boutique art Moval Lyon | Cartons | Accessoires déménagement et
          emballage |Manutention et transport
        </title>
        <meta
          name="description"
          content="Profitez de nos kits de déménagement | Avec art Moval simplifiez vous la vie pour votre déménagement | Boutique en ligne cartons et accessoires"
        />
      </Helmet>
      <header className="sticky top-0 z-50 bg-white shadow-xl">
        <Header />
      </header>

      <div class="relative max-w-7xl mx-auto h-full lg:flex">
        <main>
          <Ariane fil_ariane={mon_fil} />

          <div class="flex flex-1 overflow-hidden pr-4">
            <div class="flex flex-1 flex-col">
              <div class="flex flex-1 bg-gray-50 overflow-y-auto paragraph">
                <div className="relative bg-gray-50 overflow-hidden">
                  <div className="relative px-4 sm:px-6 lg:px-4">
                    <div className="text-lg mx-auto">
                      <h1>
                        <span className="mt-8 mb-4 block text-5xl text-left leading-8 font-bold tracking-tight text-art-marron-fonce sm:text-4xl lg:text-6xl">
                          Notre boutique
                        </span>
                      </h1>
                      {/* <p className="mt-8 mb-8 text-xl text-art-marron-fonce leading-8">
                            {excerpt}
                          </p> */}
                      {/* <div className="mt-8 text-xl flex-none text-art-orange">
                        Nos catégories
                      </div> */}
                      <ul
                        className="lg:flex mt-6 lg:space-x-3 text-xl leading-6 text-gray-600"
                      >
                        <li className="flex gap-x-3">
                          <ArrowCircleRightIcon
                            className="h-6 w-6 flex-none text-art-orange"
                            aria-hidden="true"
                          />
                          <a href="/boutique/cartons/">Cartons</a>
                        </li>
                        <li className="flex gap-x-3">
                          <ArrowCircleRightIcon
                            className="h-6 w-6 flex-none text-art-orange"
                            aria-hidden="true"
                          />
                          <a href="/boutique/accessoires-emballage/">
                            Accessoires d'emballage
                          </a>
                        </li>
                        <li className="flex gap-x-3">
                          <ArrowCircleRightIcon
                            className="h-6 w-6 flex-none text-art-orange"
                            aria-hidden="true"
                          />
                          <a href="/boutique/manutention-transport/">
                            Manutention et transport
                          </a>
                        </li>
                        <li className="flex gap-x-3">
                          <ArrowCircleRightIcon
                            className="h-6 w-6 flex-none text-art-orange"
                            aria-hidden="true"
                          />
                          <a href="/boutique/kit/">Kits</a>
                        </li>
                      </ul>
                    </div>

                    {/* <div className="mx-auto max-w-2xl pb-16 pt-10 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:pb-24 lg:pt-16">
                      <div className="col-span-1">
                        <div className="rounded-3xl shadow-lg bg-gray-900/5 p-4 ring-1 ring-inset ring-gray-900/10 lg:rounded-3xl"></div>
                      </div>
                      <div className="col-span-2 prose-h2:text-4xl prose-h3:text-3xl mt-6 max-w-none prose-a:text-art-bleu prose-headings:text-art-marron-fonce prose prose-blue prose-lg text-art-marron-fonce mx-auto">
                        test
                      </div>
                    </div> */}

                    <div className="text-lg mx-auto">
                      <div className="mx-auto max-w-2xl py-4 lg:max-w-7xl">
                        {/* <h2 className="text-2xl font-bold tracking-tight text-art-marron-fonce">
                          Voir nos kits
                        </h2> */}

                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                          <div className="group relative">
                            <a href="/boutique/cartons/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Barrel petit modèle assiettes"
                                    src="../../images/produits/barrel-petit-modele-assiette.jpg"
                                  />
                                </div>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/cartons/">
                              <div className="rounded-3xl  group-hover:opacity-75">
                                <div className="mt-4 grid">
                                  <div>
                                    <h2 className="mt-3 text-3xl font-semibold leading-6 text-art-marron-fonce group-hover:text-art-marron-fonce">
                                      Cartons
                                    </h2>
                                    <p className="mt-4 text-normal text-art-marron-fonce">
                                      Art Moval vous propose une large gamme de
                                      cartons de déménagement. Des cartons de
                                      différentes tailles, pour s'adapter à tous
                                      vos objets. Et des cartons spécifiques
                                      pour certains types d'objets, tels que les
                                      cartons pour votre livres, pour votre
                                      vaisselle, pour emballer vos lampadaires
                                      ou encore vos vêtements.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/accessoires-emballage/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Barrel grand modèle assiettes"
                                    src="../../images/produits/etiquettes-adhesives.jpg"
                                  />
                                </div>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/accessoires-emballage/">
                              <div className="mt-4 grid">
                                <div>
                                  <h2 className="mt-3 text-3xl font-semibold leading-6 text-art-marron-fonce group-hover:text-art-marron-fonce">
                                    Accessoires d'emballage
                                  </h2>
                                  <p className="mt-4 text-normal text-art-marron-fonce">
                                    Film étirable, étiquette adhésive, housse de
                                    matelas, pistolet dévidoir... Retrouvez tout
                                    ce dont vous avez besoin pour préparer votre
                                    déménagement et protéger vos effets
                                    personnels.
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/manutention-transport/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Barrel petit modèle assiettes"
                                    src="../../images/produits/bac-110-litres.jpg"
                                  />
                                </div>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/manutention-transport/">
                              <div className="rounded-3xl  group-hover:opacity-75">
                                <div className="mt-4 grid">
                                  <div>
                                    <h2 className="mt-3 text-3xl font-semibold leading-6 text-art-marron-fonce group-hover:text-art-marron-fonce">
                                      Manutention et transport
                                    </h2>
                                    <p className="mt-4 text-normal text-art-marron-fonce">
                                      Découvrez comment transporter aisément vos
                                      meubles et objets lourds grâce à nos
                                      articles de manutention et transport. Nous
                                      mettons à votre disposition des outils et
                                      matériels adaptés pour assurer la sécurité
                                      des opérateurs et des marchandises.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/kit/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Barrel grand modèle assiettes"
                                    src="../../images/produits/kit-1.jpg"
                                  />
                                </div>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/kit/">
                              <div className="mt-4 grid">
                                <div>
                                  <h2 className="mt-3 text-3xl font-semibold leading-6 text-art-marron-fonce group-hover:text-art-marron-fonce">
                                    Kits
                                  </h2>
                                  <p className="mt-4 text-normal text-art-marron-fonce">
                                    Vous ne savez pas ce dont vous avez besoin
                                    pour votre déménagement ? Art Moval vous
                                    guide et vous présente plusieurs kits de
                                    déménagement économiques qui répondent à vos
                                    besoins.
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default MdxPage;
